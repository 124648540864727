import React from 'react'
import Image from 'gatsby-image'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'

import Bio from '../components/Bio'
import Layout from '../components/Layout'
import MediumCTA from '../components/medium-cta'
import PodcastPromo from '../components/podcast-promo'
import PodlovePlayer from '../components/podlove-player'
import SEO from '../components/seo'
import SpeedrankCTA from '../components/speedrank-cta'
import { rhythm, scale } from '../utils/typography'

const components = {
  Link: Link,
  MediumCTA: MediumCTA,
  PodcastPromo: PodcastPromo,
  PodlovePlayer: PodlovePlayer,
  SpeedrankCTA: SpeedrankCTA
}

class BlogPostTemplate extends React.Component {
  render() {
    const avatar = this.props.data.avatar
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    console.log(this.props.pageContext)

    return (
      <Layout location={this.props.location} title="Speedrank - Dein Web Performance &amp; SEO Blog">
        <SEO title={post.frontmatter.title} description={post.frontmatter.metaDescription} />
        <h1>{post.frontmatter.title}</h1>
        <div
          style={{
            display: 'flex',
          }}
        >
          <Image
            fixed={avatar.childImageSharp.fixed}
            alt={post.frontmatter.author}
            style={{
              marginRight: rhythm(1 / 2),
              marginBottom: 0,
              minWidth: 50,
              borderRadius: `100%`,
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start'
            }}
          >
            <p
              style={{
                fontSize: '1.2rem',
                fontWeight: '700'
              }}
            >
              {post.frontmatter.author ? post.frontmatter.author : 'Team Speedrank'}
            </p>
            <p
              style={{
                ...scale(-1 / 5),
                marginBottom: rhythm(1),
                marginTop: rhythm(-1.2),
              }}
            >
              SEO &amp; Online Marketing Manager
            </p>
          </div>
        </div>
        <MDXProvider components={components}>
          <MDXRenderer>{post.body}</MDXRenderer>
        </MDXProvider>
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <Bio />

        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    avatar: file(absolutePath: { regex: "/profile-daniel.jpg/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      frontmatter {
        title
        metaDescription
        date(formatString: "DD. MMMM YYYY", locale: "de")
        author
      }
      body
    }
  }
`
