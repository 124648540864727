import React from 'react'

function MediumCTA(props) {
  return (
    <div style={{
      background: '#f6f6f6',
      borderRadius: '10px',
      boxShadow: 'none',
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: '2rem',
      padding: '1rem'
    }}>
      <div style={{
        display: 'flex',
        margin: 'auto'
      }}>
        <a
          class='social-icon'
          href='https://medium.com/speedrank-app'
          rel='noopener noreferrer'
          target='_blank'
          style={{ background: '#000000d6', margin: '.5rem .5rem' }}
        >
          <svg
            width='35'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
            style={{
              display: 'block',
              margin: 'auto'
            }}
          >
            <title>Medium icon</title>
            <path
              d="M0 0v24h24V0H0zm19.938 5.686L18.651 6.92a.376.376 0 0 0-.143.362v9.067a.376.376 0 0 0 .143.361l1.257 1.234v.271h-6.322v-.27l1.302-1.265c.128-.128.128-.165.128-.36V8.99l-3.62 9.195h-.49L6.69 8.99v6.163a.85.85 0 0 0 .233.707l1.694 2.054v.271H3.815v-.27L5.51 15.86a.82.82 0 0 0 .218-.707V8.027a.624.624 0 0 0-.203-.527L4.019 5.686v-.27h4.674l3.613 7.923 3.176-7.924h4.456v.271z"
              fill='#fff'
            />
          </svg>
        </a>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '10px'
      }}>
        <h2 style={{ fontSize: '1.25rem', margin: '0' }}>German is Greek to you?</h2>
        <p style={{ margin: '0' }}>Read the <a {...props}>English version</a> of this article now on <strong>Medium</strong>.</p>
      </div>
    </div>
  )
}

export default MediumCTA
