import React from 'react'
import Button from './button'
import Hero from '../../content/assets/speedrank-hero.png'

function SpeedrankCTA() {
  return (
    <div>
      <img
        src={Hero}
        style={{
          margin: '-1.75rem auto 0',
          display: 'block',
          width: '75%',
        }}
        alt="Speedrank Hero Maskottchen"
        title="Speedrank Hero Maskottchen"
      />
      <div
        style={{
          background: '#f6f6f6',
          borderRadius: '10px',
          boxShadow: 'none',
          marginBottom: '2rem',
          padding: '10px 30px',
        }}
      >
        <h2 style={{ marginTop: '20px' }}>Führe Deine Webseiten zum Erfolg</h2>
        <p>
          Analysiere mit Speedrank jetzt kostenfrei die Performance Deiner
          Webseiten. Steigere im Handumdrehen Deinen Pagespeed und erhöhe Deine
          Conversion.
        </p>
        <ul style={{ listStyle: 'none' }}>
          <li style={{ marginBottom: '0' }}>✓ 24/7 Pagespeed Monitoring.</li>
          <li style={{ marginBottom: '0' }}>✓ Keine Kündigung erforderlich!</li>
          <li style={{ marginBottom: '0' }}>✓ Kein Passwort notwendig.</li>
          <li style={{ marginBottom: '0' }}>
            ✓ In nur wenigen Sekunden die erste Auswertung sehen.
          </li>
        </ul>
        <Button href="https://speedrank.app/de/nutzer/anlegen?utm_source=blog&utm_medium=cta&utm_campaign=blog_promo">
          Jetzt kostenfrei testen
        </Button>
      </div>
    </div>
  )
}

export default SpeedrankCTA
