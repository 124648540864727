import React from 'react'
import ScriptTag from 'react-script-tag'

const PodlovePlayer = (props) => (
  <div>
    <ScriptTag id={props.id} src={props.src} />
  </div>
)

export default PodlovePlayer
